import orchestrator from 'orchestrator';
import loadingScreen from 'orchestrator/loading-screen';
import singleSpa, { handleError } from 'single-spa-client';

(async () => {
  loadingScreen.show();

  try {
    await orchestrator.registerStores();
    await orchestrator.registerServices(singleSpa);
  } catch (e) {
    handleError(e);
  } finally {
    loadingScreen.hide();
  }

  singleSpa.start();
})();
