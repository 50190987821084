import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
let previousLocation = browserHistory.location;

browserHistory.listen((location) => {
  if (
    (!location.state || location.state.resetScroll)
    && location.pathname !== previousLocation.pathname
  ) {
    window.scrollTo(0, 0);
  }

  previousLocation = location;
});

export default browserHistory;
