import decode from 'jwt-decode';
import axios from 'axios';
import { buildQuery, readCookie, readCookies } from 'utils';
import history from './history';

const ID_TOKEN_KEY = '';
const ACCESS_TOKEN_KEY = '';
const ACCESS_TOKEN_PREFIX = 'Bearer ';
const LOGIN_URL = '/';
const REFRESH_TOKENS_URL = '/';

const logout = ({ withRedirect = false } = {}) => {
  const params = {};
  if (withRedirect) {
    params['redirect-url'] = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  }

  const query = buildQuery(params);

  let url = LOGIN_URL;
  if (query) {
    url = `${url}?${query}`;
  }

  history.push(url);
};

const getTokens = ({
  forAuth = false,
} = {}) => {
  const {
    [ID_TOKEN_KEY]: idToken = null,
    [ACCESS_TOKEN_KEY]: accessToken = null,
  } = readCookies(ID_TOKEN_KEY, ACCESS_TOKEN_KEY);

  if (forAuth && !accessToken.startsWith(ACCESS_TOKEN_PREFIX)) {
    return {
      idToken,
      accessToken: ACCESS_TOKEN_PREFIX + accessToken,
    };
  }

  return { idToken, accessToken };
};

const refreshTokens = async () => {
  try {
    await axios.post(REFRESH_TOKENS_URL);
  } catch (e) {
    logout({ withRedirect: true });
  }
};

const getUserInfo = () => {
  const idToken = readCookie(ID_TOKEN_KEY);

  try {
    const {
      email = null,
    } = decode(idToken);

    return {
      email,
    };
  } catch (e) {
    return {
      email: null,
    };
  }
};

const getUserAccess = () => {
  const { accessToken } = getTokens();

  try {
    const {
      scope = '',
    } = decode(accessToken);

    return {
      scope: scope.split(' '),
    };
  } catch (e) {
    return {
      scope: [],
    };
  }
};

export {
  getTokens,
  refreshTokens,
  getUserInfo,
  getUserAccess,
  logout,
};
