/// <reference path="typedefs.js" />

import { getEnv } from 'utils';

/** @type {Service[]} */
const SERVICES = [
  {
    id: 'NAVIGATION',
    baseUrl: getEnv('PLAYGROUND_FOUNDATION_BASE_URL'),
    manifestFileName: 'playground-manifest.json',
    moduleName: 'navigation',
    pathRegex: /^\/(?!(500)).*\/?$/,
    required: true,
    type: 'utility',
  },
  {
    id: 'ERROR_PAGE',
    baseUrl: getEnv('PLAYGROUND_FOUNDATION_BASE_URL'),
    manifestFileName: 'playground-manifest.json',
    moduleName: 'error-page',
    pathRegex: /^\/(403|404|500)\/?$/,
    required: true,
    type: 'service',
  },
  {
    id: 'PROFILE',
    baseUrl: getEnv('PLAYGROUND_PROFILE_BASE_URL'),
    manifestFileName: 'playground-manifest.json',
    moduleName: 'profile',
    pathRegex: /^(\/about)?\/?$/,
    required: true,
    type: 'service',
  },
];

/** @type {Store[]} */
const STORES = [];

export {
  SERVICES,
  STORES,
};
