/* eslint-disable no-console */

import axios from 'axios';
import * as uuid from 'uuid';
import { mapStackTrace } from 'sourcemapped-stacktrace';

const RETRY_TIMES = 3;

const getStack = (stack) => new Promise(
  (resolve) => {
    try {
      mapStackTrace(stack, (mappedStack) => resolve(mappedStack.join()));
    } catch (e) {
      resolve(stack);
    }
  },
);

const getPayload = async (level, msg, others = {}) => {
  const { id = uuid.v4() } = others;
  let { stack, data = {} } = others;
  let message = msg;

  if (others.error) {
    stack = others.error.stack;
    message = others.error.message;
  }

  if (msg instanceof Error) {
    message = msg.message;
    stack = msg.stack;
    data = {};
  }

  if (stack) {
    data.stack = await getStack(stack);
  }

  return {
    id,
    location: window.location.href,
    service: 'Playground Orchestrator',
    userAgent: window.navigator.userAgent,
    message,
    level,
    data,
  };
};

const log = async (payload, retry = RETRY_TIMES) => {
  const { REPORTING_SERVICE_ENDPOINT } = process.env;
  if (!REPORTING_SERVICE_ENDPOINT) {
    console.warn('Reporting service endpoint not found.');

    return;
  }

  try {
    await axios.post(REPORTING_SERVICE_ENDPOINT, payload);
  } catch (e) {
    if (retry) {
      await log(payload, retry - 1);

      return;
    }

    console.error(e);
  }
};

const logger = {
  emergency: async (message, data = {}) => {
    const payload = await getPayload('emergency', message, data);
    console.error(message, data);

    return log(payload);
  },
  emerg: async (message, data = {}) => {
    const payload = await getPayload('emergency', message, data);
    console.error(message, data);

    return log(payload);
  },
  alert: async (message, data = {}) => {
    const payload = await getPayload('alert', message, data);
    console.error(message, data);

    return log(payload);
  },
  critical: async (message, data = {}) => {
    const payload = await getPayload('critical', message, data);
    console.error(message, data);

    return log(payload);
  },
  error: async (message, data = {}) => {
    const payload = await getPayload('error', message, data);
    console.error(message, data);

    return log(payload);
  },
  err: async (message, data = {}) => {
    const payload = await getPayload('error', message, data);
    console.error(message, data);

    return log(payload);
  },
  warning: async (message, data = {}) => {
    const payload = await getPayload('warning', message, data);
    console.warn(message, data);

    return log(payload);
  },
  warn: async (message, data = {}) => {
    const payload = await getPayload('warning', message, data);
    console.warn(message, data);

    return log(payload);
  },
  notice: async (message, data = {}) => {
    const payload = await getPayload('notice', message, data);
    console.log(message, data);

    return log(payload);
  },
  info: async (message, data = {}) => {
    const payload = await getPayload('info', message, data);
    console.log(message, data);

    return log(payload);
  },
  debug: async (message, data = {}) => {
    const payload = await getPayload('debug', message, data);
    console.debug(message, data);

    return log(payload);
  },
};

export default logger;
