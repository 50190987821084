const compose = (...funcs) => {
  if (funcs.length === 0) {
    return (arg) => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
};

const getEnv = (key, def = undefined) => {
  if (process.env[key] === undefined) {
    if (def === undefined) {
      throw new Error(`Unable to fetch environment variable ${key}`);
    }

    return def;
  }

  return process.env[key];
};

const readCookie = (key, cachedCookies = []) => {
  let cookies = cachedCookies;
  if (!cookies.length) {
    cookies = window.decodeURIComponent(document.cookie)
      .split(';')
      .map((cookie) => cookie.trim());
  }

  const cookie = cookies
    .find((_cookie) => _cookie.indexOf(key) === 0);
  if (!cookie) {
    return undefined;
  }

  const [_key, value] = cookie
    .split('=')
    .map((_cookie) => _cookie.trim());

  if (value === undefined) {
    return _key;
  }

  return value;
};

const readCookies = (...keys) => {
  const cookies = window.decodeURIComponent(document.cookie)
    .split(';')
    .map((cookie) => cookie.trim());
  return keys.reduce(
    (acc, key) => {
      acc[key] = readCookie(key, cookies);

      return acc;
    },
    {},
  );
};

const ensureSlash = (inputPath, needsSlash = true) => {
  const hasSlash = inputPath.endsWith('/');
  if (hasSlash && !needsSlash) {
    return inputPath.substr(0, -1);
  }

  if (!hasSlash && needsSlash) {
    return `${inputPath}/`;
  }

  return inputPath;
};

const buildQuery = (params) => (
  Object.entries(params)
    .map(([key, value]) => `${window.encodeURIComponent(key)}=${window.encodeURIComponent(value)}`)
    .join('&')
);

export {
  compose,
  getEnv,
  readCookie,
  readCookies,
  ensureSlash,
  buildQuery,
};
