import logger from 'logger';
import history from 'props/history';
import * as singleSpa from 'single-spa';
import { buildQuery } from 'utils';
import * as uuid from 'uuid';
import { SERVICES } from '../config';

const handleError = (e) => {
  const id = uuid.v4();
  logger.err(e, { id });

  if (window.location.pathname === '/500') {
    throw e;
  }

  const params = {
    'error-id': id,
  };
  const query = buildQuery(params);

  history.replace(`/500?${query}`);
};

singleSpa.addErrorHandler(handleError);

window.addEventListener('single-spa:routing-event', () => {
  const hasServiceLoaded = SERVICES
    .filter(({ type = 'service' }) => type === 'service')
    .map(({ id }) => id)
    .some(
      (id) => {
        const status = singleSpa.getAppStatus(id);

        switch (status) {
          case singleSpa.LOADING_SOURCE_CODE:
          case singleSpa.BOOTSTRAPPING:
          case singleSpa.MOUNTING:
          case singleSpa.MOUNTED: {
            return true;
          }
          default: {
            return false;
          }
        }
      },
    );

  if (!hasServiceLoaded && (window.location.pathname !== '/404' && window.location.pathname !== '/500')) {
    logger.err(`Visiting a URL where no service is picking up: ${window.location.href}`);

    history.replace('/404');
  }
});

export {
  handleError,
};

export default singleSpa;
