let loadingCount = 0;
let containerEl = null;

const loadingScreen = {
  show() {
    if (!loadingCount && !containerEl) {
      containerEl = document.createElement('div');
      containerEl.classList.add('service-loading-wrapper');

      const spinnerEl = document.createElement('div');
      spinnerEl.classList.add('spinner-container');

      const dots = [
        document.createElement('span'),
        document.createElement('span'),
        document.createElement('span'),
      ];

      dots.forEach((dot) => spinnerEl.appendChild(dot));
      containerEl.appendChild(spinnerEl);

      document.body.appendChild(containerEl);
    }

    loadingCount += 1;
  },
  hide() {
    if (loadingCount) {
      loadingCount -= 1;
    }

    if (!loadingCount && containerEl) {
      document.body.removeChild(containerEl);

      containerEl = null;
    }
  },
};

export default loadingScreen;
