import { getStoreProps, getServiceProps } from 'props';
import globalStore from 'props/global-store';
import { STORES, SERVICES } from '../../config';
import loadingScreen from './loading-screen';
import serviceLoader from './service-loader';

const activeWhen = (pathRegex) => (location) => (
  pathRegex.test(location.pathname)
);

const registerStores = async () => {
  const storeProps = await getStoreProps();

  const promises = STORES.map(
    (storeConfig) => serviceLoader.loadStoreFromConfig(storeConfig, storeProps),
  );

  await Promise.all(promises);
};

const registerServices = async (singleSpa) => {
  const serviceProps = { ...await getServiceProps(), globalStore };

  SERVICES.forEach(
    (serviceConfig) => {
      const { id, pathRegex, storeId = null } = serviceConfig;
      const loadService = async () => {
        loadingScreen.show();

        try {
          return serviceLoader.loadLifecyclesFromConfig(serviceConfig);
        } finally {
          loadingScreen.hide();
        }
      };
      let store;
      if (storeId) {
        store = globalStore.getStore(storeId);
      }

      return singleSpa.registerApplication(
        id, loadService, activeWhen(pathRegex), { ...serviceProps, store },
      );
    },
  );
};

const orchestrator = {
  registerStores,
  registerServices,
};

export default orchestrator;
