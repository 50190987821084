import logger from 'logger';
import globalStore from 'props/global-store';
import { ensureSlash } from 'utils';
import moduleLoader from './module-loader';

const RETRY_TIMES = 3;

const extractModuleFileUrls = async (manifest, moduleName) => {
  const fileUrls = manifest[moduleName].map((url) => new URL(url));

  const mainFileRegex = RegExp(`^${moduleName}.*\\.js`);

  const [mainFileUrl, dependencyFileUrls] = fileUrls
    .reduce(([_mainFileUrl, _dependencyFileUrls], url) => {
      const filename = url.pathname.split('/').pop();
      const isMatch = mainFileRegex.test(filename);
      if (isMatch) {
        return [url.href, _dependencyFileUrls];
      }

      return [_mainFileUrl, [..._dependencyFileUrls, url.href]];
    }, [null, []]);

  if (!mainFileUrl) {
    throw new Error('Manifest does not contain the main file');
  }

  return { mainFileUrl, dependencyFileUrls };
};

const loadDependencies = async (dependencyFileUrls, retry = RETRY_TIMES) => {
  try {
    return Promise.all(
      dependencyFileUrls.map((url) => moduleLoader.loadModule(url)),
    );
  } catch (e) {
    if (retry) {
      return loadDependencies(dependencyFileUrls, retry - 1);
    }

    throw e;
  }
};

const loadStore = async ({
  mainFileUrl, moduleName, type, exportName, props,
}, retry = RETRY_TIMES) => {
  try {
    await moduleLoader.loadModule(mainFileUrl);
    const store = window[moduleName];
    if (!store[exportName]) {
      throw new Error(`Store main file for ${moduleName} declared in Manifest does not export as ${exportName} as declared in "exportName"`);
    }

    switch (type) {
      case 'instance': {
        return store[exportName];
      }
      case 'factory': {
        const createStore = store[exportName];

        try {
          return createStore(props);
        } catch (e) {
          return undefined;
        }
      }
      default: {
        return undefined;
      }
    }
  } catch (e) {
    if (retry) {
      return loadStore({
        mainFileUrl, moduleName, type, exportName, props,
      }, retry - 1);
    }

    return undefined;
  }
};

const loadLifeCycles = async ({ mainFileUrl, moduleName, required }, retry = RETRY_TIMES) => {
  try {
    await moduleLoader.loadModule(mainFileUrl);
    const { bootstrap, mount, unmount } = window[moduleName] || {};
    if (required && !(bootstrap && mount && unmount)) {
      throw new Error(`Lifecycles main file for ${moduleName} declared in Manifest does not export all of bootstrap, mount, and unmount functions`);
    }

    const emptyFunc = async () => {};

    return {
      bootstrap: bootstrap || emptyFunc,
      mount: mount || emptyFunc,
      unmount: unmount || emptyFunc,
    };
  } catch (e) {
    if (retry) {
      return loadLifeCycles({ mainFileUrl, moduleName, required }, retry - 1);
    }

    throw e;
  }
};

const loadStoreFromConfig = async ({
  id, baseUrl, manifestFileName = 'playground-manifest.json', moduleName = 'main', type = 'instance', exportName,
}, props) => {
  try {
    const manifest = await moduleLoader.loadJSON(ensureSlash(baseUrl) + manifestFileName);
    const { mainFileUrl, dependencyFileUrls } = await extractModuleFileUrls(manifest, moduleName);
    await loadDependencies(dependencyFileUrls);

    const store = await loadStore({
      mainFileUrl, moduleName, type, exportName, props,
    });
    if (store) {
      globalStore.registerStore(id, store);
    }
  } catch (e) {
    logger.err(e);
  }
};

const loadLifecyclesFromConfig = async ({
  baseUrl, manifestFileName = 'playground-manifest.json', moduleName = 'main', required = false,
}) => {
  try {
    const manifest = await moduleLoader.loadJSON(ensureSlash(baseUrl) + manifestFileName);
    const { mainFileUrl, dependencyFileUrls } = await extractModuleFileUrls(manifest, moduleName);
    await loadDependencies(dependencyFileUrls);

    return loadLifeCycles({ mainFileUrl, moduleName, required });
  } catch (e) {
    if (required) {
      throw e;
    }

    const emptyFunc = async () => {};

    return {
      bootstrap: emptyFunc,
      mount: emptyFunc,
      unmount: emptyFunc,
    };
  }
};

const serviceLoader = {
  loadStoreFromConfig,
  loadLifecyclesFromConfig,
};

export default serviceLoader;
