import axios from 'axios';

const RETRY_TIMES = 3;

const loadJSON = async (url, retry = RETRY_TIMES) => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    if (retry) {
      return loadJSON(url, retry - 1);
    }

    throw e;
  }
};

const getExtension = (url) => (
  url.split(/#|\?/)[0].split('.').pop().trim()
);

const getElementByUrl = (url) => {
  const extension = getExtension(url);

  switch (extension) {
    case 'js': {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.src = url;

      return script;
    }
    case 'css': {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url;

      return link;
    }
    default: {
      throw new Error(`Unsupported extension ${extension}`);
    }
  }
};

const loadModule = async (url) => (
  new Promise((resolve, reject) => {
    let err;
    const windowErrorListener = (evt) => {
      err = evt.error;
    };

    window.addEventListener('error', windowErrorListener);

    const element = getElementByUrl(url);
    element.addEventListener('error', () => {
      window.removeEventListener('error', windowErrorListener);
      document.head.removeChild(element);

      reject(new Error(`Error loading ${url}`));
    });
    element.addEventListener('load', () => {
      window.removeEventListener('error', windowErrorListener);

      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });

    document.head.appendChild(element);
  })
);

const moduleLoader = {
  loadJSON,
  loadModule,
};

export default moduleLoader;
