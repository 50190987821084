let pageTitle = '';

const setDocumentTitle = () => {
  let documentTitle = pageTitle;
  if (documentTitle) {
    documentTitle = `${documentTitle} | `;
  }

  documentTitle = `${documentTitle}Playground`;

  document.title = documentTitle;
};

const setPageTitle = (newPageTitle) => {
  pageTitle = newPageTitle.trim();

  setDocumentTitle();
};

const resetPageTitle = () => {
  pageTitle = '';

  setDocumentTitle();
};

export {
  resetPageTitle,
  setPageTitle,
};
