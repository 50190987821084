class GlobalStore {
  constructor() {
    this.stores = {};
  }

  registerStore(storeName, store) {
    this.stores[storeName] = store;
  }

  getStore(storeName) {
    return this.stores[storeName];
  }

  dispatch(action) {
    Object
      .values(this.stores)
      .forEach((store) => {
        store.dispatch(action);
      });
  }
}

const globalStore = new GlobalStore();

export default globalStore;
